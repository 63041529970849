.home {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
.home_panelList-wrap,
.home_panel-wrap,
.home_list-wrap {
  overflow-y: auto;
}

.home_panelList-wrap {
  flex: 1;
  display: flex;
}
.home_panel-wrap {
  flex-basis: 280px;
  padding: 1rem;
}
.home_list-wrap {
  flex: 1;
  padding: 1rem;
}
