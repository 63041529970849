.emptyView-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.emptyView-wrap img {
  max-width: 500px;
  width: 100%;
}
