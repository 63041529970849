.listItem-wrap img {
  width: 100%;
  border-radius: 15px;
  height: 200px;
  object-fit: cover;
}
.listItem-wrap header {
  display: flex;
  justify-content: space-between;
  margin: 1rem 0 1rem 0;
  align-items: center;
}

.listItem-wrap header span {
  font-size: 0.7rem;
  background-color: #97ffff;
  padding: 0.5rem;
  border-radius: 10px;
}
.listItem-wrap footer {
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.listItem-wrap footer p:first-child span {
  color: rgba(0, 0, 0, 0.26);
  font-weight: 600;
}
